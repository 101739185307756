components:
  language_switcher:
    change_language: Cambia lingua
  theme_switcher:
    theme: tema
    change_theme: cambia tema
pages:
  404:
    title: Pagina non trovata
  aboutus:
    nav: Chi siamo
    title: Kmetija grgar su di noi
    description: Kmetija grgar chi siamo pagina
  pricelist:
    nav: Listino prezzi
    title: Listino prezzi kmetija grgar
    description: questa è una pagina del listino prezzi
  contact:
    nav: Contatto
    title: kmetija grgar contatto
    description: kmetija grgar contatto descrizione
  antibiotics:
    nav: Antibiotici
    title: kmetija grgar antibiotici
    description: kmtetija grgar descrizione degli antibiotici
  body:
    offer: ti offriamo
  index:
    title: veri[]premium[]polli
    body:
      offer: ti offriamo
    button-banner1: ordine
    button-banner2: chi siamo
  blank:
    nav: vuoto
    title: pagina vuota
    description: questa è una pagina bianca
    just_blank_page_with_title: pagina per ordini
  order:
    nav: Ordine
    title: Ordine
    index:
      nav: Ordine
      title: Ordine
  registration:
    nav: Registra accounto
    title: Registra accounto
    index:
      nav: Registra accounto
      title: Registra accounto
  login:
    nav: Login
    title: Login
    index:
      nav: Login per accoutn
      title: Login per account
  user:
    nav: Utente
    title: Utente impostazioni
    index:
      nav: utente impostazioni
      title: utente impostazioni
  orders:
    nav: Ordini
    title: Ordini degli utenti
    index:
      nav: Ordini
      title: Ordini degli utenti
banners:
  welcome: ciao, benvenuto in %{app_name}!
others:
  learn_more: saperne di più
  copy: copia
  enabled: abilitato
  disabled: disabilitato
