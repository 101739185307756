import { default as _404STh7p7T7N0Meta } from "/app/pages/404.vue?macro=true";
import { default as about_45usWjawxlkv4gMeta } from "/app/pages/about-us.vue?macro=true";
import { default as contactGnllzqhygbMeta } from "/app/pages/dashboard/contact.vue?macro=true";
import { default as indexhg2nWJrbRRMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as loginC0hy7qPeJJMeta } from "/app/pages/dashboard/login.vue?macro=true";
import { default as logoutACw6MCFmNqMeta } from "/app/pages/dashboard/logout.vue?macro=true";
import { default as password_45resetBQ3LNb5D9HMeta } from "/app/pages/dashboard/password-reset.vue?macro=true";
import { default as registrationnUGf1ecAJrMeta } from "/app/pages/dashboard/registration.vue?macro=true";
import { default as terms_45conditionsfdA4tL8Mk2Meta } from "/app/pages/dashboard/terms-conditions.vue?macro=true";
import { default as _91order_id_93IRNyYbQ1TxMeta } from "/app/pages/dashboard/thank-you/[order_id].vue?macro=true";
import { default as userb0WTMXGJ95Meta } from "/app/pages/dashboard/user.vue?macro=true";
import { default as health_45and_45qualityeGv6UOwIIUMeta } from "/app/pages/health-and-quality.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as our_45productslae3EtpvTTMeta } from "/app/pages/our-products.vue?macro=true";
export default [
  {
    name: _404STh7p7T7N0Meta?.name ?? "404",
    path: _404STh7p7T7N0Meta?.path ?? "/404",
    meta: _404STh7p7T7N0Meta || {},
    alias: _404STh7p7T7N0Meta?.alias || [],
    redirect: _404STh7p7T7N0Meta?.redirect || undefined,
    component: () => import("/app/pages/404.vue").then(m => m.default || m)
  },
  {
    name: about_45usWjawxlkv4gMeta?.name ?? "about-us",
    path: about_45usWjawxlkv4gMeta?.path ?? "/about-us",
    meta: about_45usWjawxlkv4gMeta || {},
    alias: about_45usWjawxlkv4gMeta?.alias || [],
    redirect: about_45usWjawxlkv4gMeta?.redirect || undefined,
    component: () => import("/app/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: contactGnllzqhygbMeta?.name ?? "dashboard-contact",
    path: contactGnllzqhygbMeta?.path ?? "/dashboard/contact",
    meta: contactGnllzqhygbMeta || {},
    alias: contactGnllzqhygbMeta?.alias || [],
    redirect: contactGnllzqhygbMeta?.redirect || undefined,
    component: () => import("/app/pages/dashboard/contact.vue").then(m => m.default || m)
  },
  {
    name: indexhg2nWJrbRRMeta?.name ?? "dashboard",
    path: indexhg2nWJrbRRMeta?.path ?? "/dashboard",
    meta: indexhg2nWJrbRRMeta || {},
    alias: indexhg2nWJrbRRMeta?.alias || [],
    redirect: indexhg2nWJrbRRMeta?.redirect || undefined,
    component: () => import("/app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: loginC0hy7qPeJJMeta?.name ?? "dashboard-login",
    path: loginC0hy7qPeJJMeta?.path ?? "/dashboard/login",
    meta: loginC0hy7qPeJJMeta || {},
    alias: loginC0hy7qPeJJMeta?.alias || [],
    redirect: loginC0hy7qPeJJMeta?.redirect || undefined,
    component: () => import("/app/pages/dashboard/login.vue").then(m => m.default || m)
  },
  {
    name: logoutACw6MCFmNqMeta?.name ?? "dashboard-logout",
    path: logoutACw6MCFmNqMeta?.path ?? "/dashboard/logout",
    meta: logoutACw6MCFmNqMeta || {},
    alias: logoutACw6MCFmNqMeta?.alias || [],
    redirect: logoutACw6MCFmNqMeta?.redirect || undefined,
    component: () => import("/app/pages/dashboard/logout.vue").then(m => m.default || m)
  },
  {
    name: password_45resetBQ3LNb5D9HMeta?.name ?? "dashboard-password-reset",
    path: password_45resetBQ3LNb5D9HMeta?.path ?? "/dashboard/password-reset",
    meta: password_45resetBQ3LNb5D9HMeta || {},
    alias: password_45resetBQ3LNb5D9HMeta?.alias || [],
    redirect: password_45resetBQ3LNb5D9HMeta?.redirect || undefined,
    component: () => import("/app/pages/dashboard/password-reset.vue").then(m => m.default || m)
  },
  {
    name: registrationnUGf1ecAJrMeta?.name ?? "dashboard-registration",
    path: registrationnUGf1ecAJrMeta?.path ?? "/dashboard/registration",
    meta: registrationnUGf1ecAJrMeta || {},
    alias: registrationnUGf1ecAJrMeta?.alias || [],
    redirect: registrationnUGf1ecAJrMeta?.redirect || undefined,
    component: () => import("/app/pages/dashboard/registration.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsfdA4tL8Mk2Meta?.name ?? "dashboard-terms-conditions",
    path: terms_45conditionsfdA4tL8Mk2Meta?.path ?? "/dashboard/terms-conditions",
    meta: terms_45conditionsfdA4tL8Mk2Meta || {},
    alias: terms_45conditionsfdA4tL8Mk2Meta?.alias || [],
    redirect: terms_45conditionsfdA4tL8Mk2Meta?.redirect || undefined,
    component: () => import("/app/pages/dashboard/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: _91order_id_93IRNyYbQ1TxMeta?.name ?? "dashboard-thank-you-order_id",
    path: _91order_id_93IRNyYbQ1TxMeta?.path ?? "/dashboard/thank-you/:order_id",
    meta: _91order_id_93IRNyYbQ1TxMeta || {},
    alias: _91order_id_93IRNyYbQ1TxMeta?.alias || [],
    redirect: _91order_id_93IRNyYbQ1TxMeta?.redirect || undefined,
    component: () => import("/app/pages/dashboard/thank-you/[order_id].vue").then(m => m.default || m)
  },
  {
    name: userb0WTMXGJ95Meta?.name ?? "dashboard-user",
    path: userb0WTMXGJ95Meta?.path ?? "/dashboard/user",
    meta: userb0WTMXGJ95Meta || {},
    alias: userb0WTMXGJ95Meta?.alias || [],
    redirect: userb0WTMXGJ95Meta?.redirect || undefined,
    component: () => import("/app/pages/dashboard/user.vue").then(m => m.default || m)
  },
  {
    name: health_45and_45qualityeGv6UOwIIUMeta?.name ?? "health-and-quality",
    path: health_45and_45qualityeGv6UOwIIUMeta?.path ?? "/health-and-quality",
    meta: health_45and_45qualityeGv6UOwIIUMeta || {},
    alias: health_45and_45qualityeGv6UOwIIUMeta?.alias || [],
    redirect: health_45and_45qualityeGv6UOwIIUMeta?.redirect || undefined,
    component: () => import("/app/pages/health-and-quality.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: our_45productslae3EtpvTTMeta?.name ?? "our-products",
    path: our_45productslae3EtpvTTMeta?.path ?? "/our-products",
    meta: our_45productslae3EtpvTTMeta || {},
    alias: our_45productslae3EtpvTTMeta?.alias || [],
    redirect: our_45productslae3EtpvTTMeta?.redirect || undefined,
    component: () => import("/app/pages/our-products.vue").then(m => m.default || m)
  }
]