<script lang="ts" setup>
import { AppConfigInput } from 'nuxt/schema'
import { AppSetup } from './utils/app'
import { ITheme } from './utils/theme'
AppSetup()
const theme = useState<ITheme>('theme.current')
const locale = useState<string>('locale.setting')
const app = useAppConfig() as AppConfigInput

useHead({
  title: app.name,
  titleTemplate: '%s - Kmetija Grgar',
  meta: [
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    {
      hid: 'description',
      name: 'description',
      content:
        'Domače piščančje meso naravne pridelave, prvovrstni fileji, hrenovke, perutničke in bedrca domače reje. Enostavno naročilo preko spleta in dostava.',
    },
  ],
  link: [{ rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' }],
})
</script>

<template>
  <Html :class="`${theme === 'light' ? 'light' : ''}`" :lang="locale">
    <Body
      class="antialiased duration-300 transition-colors text-black bg-beige bg-website-bg-texture bg-repeat"
    >
      <NuxtLayout>
        <NuxtLoadingIndicator :height="5" :duration="3000" :throttle="400" />
        <NuxtPage />
      </NuxtLayout>
    </Body>
  </Html>
</template>
