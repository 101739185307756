export const appPageTransition = {"name":"page","mode":"out-in"}

export const appLayoutTransition = {"name":"layout","mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[],"style":[],"script":[],"noscript":[]}

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"