components:
  language_switcher:
    change_language: Spremeni jezik
  theme_switcher:
    theme: theme
    change_theme: change theme
  questionsEmail:
    title: Vprašanja?
    subtitle: Kontaktirajte nas prek telefona ali spodnjega obrazca
    description: Želite opraviti večje naročilo ? Vas zanima B2B prodaja? Pišite nam in kontaktirali Vas bomo v najkrajšem možnem času
    nameAndSurname: Ime in priimek
    nameAndSurnamePlaceholder: Vaš ime in priimek...
    email: E-pošta
    emailPlaceholder: Vaša pošta...
    yourQuestion: Vaše vprašanje
    yourQuestionPlaceholder: Vaša vprašanja, komentarji, mnenja...
    error: Nekatera polja so prazna. Izpolnite polja!
  sectionOrderNow:
    title: Ne zamudi okusnih piščancev Kmetije Grgar
  map:
    title: Kje se nahajamo?

pages:
  aboutus:
    nav: O nas
    title: Kmetija Grgar kdo smo
    description: Kmetija grgar kdo smo stran
    hero:
      title: O kmetiji Grgar
      subtitle: že od dela 1920
    sectionHistory:
      title: Zgodovina kmetije
      description: Naša kmetija leži na začetku vasi Grgar, v zavetrju Svete gore. Zgodovina kmetije sega v leto 1920, na njej pa se z rejo piščancev ukvarjamo že od leta 1967. Stremimo k trajnostnemu razvoju in ničelni uporabi pesticidov in drugih za okolje škodljivih snovi.
      description2: Krmo za piščance pripravljamo sami s pomočjo lokalnih kmetov, ki podpirajo zeleno kmetijstvo in ekološko pridelavo.
      subtitle: Zaveza trajnostnemu razvoju in ekološki pridelavi
      description3: Začetki naše kmetije segajo v leto 1920, ko je bila zgrajena hiša in večji hlev za živino. Moj pranono Jožef je postal lastnik kmetije in ženo Milko sta si ustvarila družino. Rodila sta se jim dva sina. Ko je bil Miro star komaj 14. let, je nenadoma izgubil očeta, po dveh letih pa še mlajšega brata. Tragedija je pustila mater in 16 letnega sina sama na kmetiji, ki jima je pomenila edini vir preživetja v povojnih časih.
      description4: Leta 1967 se je Miru ponudila priložnost, da bi redil perutnino za podjetje Pivka-Kras Perutninarstvo. Priložnost je zagrabil z vso vnemo in se podal novim izzivom naproti. Prvi vzrejni ciklus (turnus) je štel 3.400 piščancev, pozneje pa je svoje prostorske zmogljivosti razširil na končnih 10.000 piščancev na vzrejni ciklus.
      description5: Proti koncu sodelovanja s Pivko sem nonotu Miru veliko pomagal tudi sam in začutil izziv in potrebo po preobrazbi kmetije. Priložnost se je namreč ponujala v trajnostnem razvoju in vse večjem povpraševanju po kakovostnih, ekološko neoporečnih izdelkih. Pri svojih 20 letih sem namenil pozornost ekološkemu načinu vzreje in predelave piščancev.
      subtitle2: 50-letno delo in iskanje vrhunske kakovosti
      description6: To, kar vam danes lahko ponudim, je rezultat 50-letnega dela mojega nonota ter več let mojih preizkušanj, posvetovanj in iskanja najboljših vzrejnih prijemov za doseganje nadpovprečne kakovosti piščančjega mesa.
      description7: Na kakovost, zdravje in okus naših izdelkov skrbim jaz, Kevin Reja.
    sectionAboutMe:
      title: O meni
      subtitle: Od otroštva do ljubezni do narave, iskanje trajnostnih rešitev
      description: Kot otrok sem s strastjo pomagal nonotu na kmetiji, vedno fasciniran nad naravo in njenimi čudeži. Opazovati, kako iz enega zrna koruze zraste na tisoče novih zrn, ali kako majhna kepica piščanca v nekaj tednih postane okusen obrok - to me je navduševalo. Ta čar narave me je prevzel že v mladosti, in še danes me ohranja. Delal sem vse vrste nalog - od najpreprostejših do najbolj neprijetnih.
      description2: Pri komaj dvanajstih letih sem prvič samostojno skrbel za vzrejo piščancev pri nonotu, kar me je izjemno navdušilo. Med leti 8 in 19 sem preživel večino prostega časa na kmetiji in spoznal, da takratni sistem dolgoročno ne bo deloval. Začel sem iskati rešitve, da bi kmetija lahko uspešno preživela. Ugotovil sem, da je najboljša možnost vzrejati, predelovati in tržiti piščance sam.
      subtitle2: Pot do inovacij in vrhunske kakovosti, neprestano učenje in izboljšave
      description3: Imel sem visoke ambicije in zamisel se je zdela preprosta za uresničitev. Toda, ko sem se soočil z izzivom, sem spoznal, da bom moral korak za korakom napredovati proti cilju.
      description4: Začetki so bili precej klasični, podobni načinu vzreje domačih piščancev, ki jih danes najdemo povsod (industrijska krma + malo pšenice in koruze). Vendar me takšen način vzreje ni zadovoljil.
      description5: Radovednost me je gnala naprej - raziskoval sem sestavo vsakega zrna, spoznaval njegove lastnosti in razumel, kako lahko izkoristim prednosti različnih kultur za sestavo popolne krmne mešanice. Takrat nisem poznal nikogar, ki bi mi podrobno pojasnil proces pridelave krme, zato sem se sam poglobil in iskal novo znanje. Več let sem posvetil preizkušanju, ugotavljanju in učenju.
      description6: Dvakrat letno sem izvajal vzrejo piščancev in preizkušal vsaj pet različnih krmnih mešanic. Po petih letih truda sem končno dosegel želene rezultate ter pridobil dragoceno znanje o procesu sestave krme.
      subtitle3: Izbor najboljše pasme za vrhunsko meso in sodobno naročanje
      description7: Po izgradnji klavnice sem v letih 2021 in 2022 začel preizkušati različne pasme piščancev z enim glavnim ciljem - doseči najbolj okusno meso. Preizkusil sem dve industrijski liniji in dve BIO-EKO liniji. Sedaj vzgajam linijo ROS308, saj zagotavlja najboljše okusno meso z minimalno izgubo vode med pripravo
      description8: Da bi ljudem po vsej Sloveniji omogočili enostaven dostop do naših odličnih piščancev, sem s prijateljem začeli razvijati spletno stran in aplikacijo za preprosto naročanje.
      subtitle4: Neprestano izboljševanje za vrhunsko kakovost in zadovoljstvo gurmanov
      description9: Morda se zdi, da sem že izčrpal vse ideje za izboljšave, a resnica je, da se z vsakim poskusom naučim nekaj novega. Novo znanje prinaša nove ideje. Vsako leto si prizadevamo narediti naš izdelek še boljši kot prejšnje leto.
      description10: Leta 2021 smo popolnoma opustili uporabo kokcidiostatikov, leta 2023 pa skušamo še dodatno izboljšati kakovost z uporabo posebne naravne sestavine.
    goal: Naš cilj ni zgolj proizvajati izdelke, ki bi konkurirali na trgu, temveč izdelovati najbolj kakovostne izdelke, ki zadovoljijo tudi najbolj zahtevne gurmane.
    sectionMilestones:
      title: Ključni mejniki na kmetiji
      milestone: Zgradi se velika kmetija v Grgarju, katere lastnik postane moj pranono.
      milestone2: Nono začne vzrejo za perutninarstvo Kras-Pivka
      milestone3: Obsežna investicija, ki poveča in izboljša Kmetijo Grgar.
      milestone4: Vnuk Kevin začne redno pomagati nonotu na kmetiji.
      milestone5: Zadnji vzrejni obrat za Pivko in začetek nove zgodbe.
      milestone6: Mladi prevzemnik uvede nove izboljšave in začne s prodajo pravih domačih piščancev.
      milestone7: Aplikacija za naročanje in razširitev ponudbe piščančjih izdelkov.
  components:
    nav: Componente
    title: Componente
  contact:
    nav: Kontakt
    title: Kmetija grgar kontaktirajte nas
    description: Kmetija grgar kontakt opis
  healthAndQuality:
    description: Stran o zdravju in kakovosti naših piščancev
    nav: Zdravje in kakovost
    title: Reja perutnine
    hero:
      title: Reja perutnine
      subtitle: brez Kokcidiostatikov in Antibiotikov
      description: Naši piščanci so vzrejeni BREZ antibiotikov in tudi BREZ ne-registriranih antibiotikov oz. krmnih dodatkov, kot je kokcidiostatik.
    section:
      title: Zdravje in kakovost pri vzreji piščancev
      subtitle1: Kokcidija - Nevarnost za piščance in ukrepi za preprečevanje
      description1: Pri reji perutnine se pogosto srečujemo z izzivom goste naselitve piščancev v gojitvenih objektih. V takih pogojih je pogost pojav zajedavca kokcidije, ki se hitro razvije na vlažnem nastilju, še posebej pogostem na velikih vzrejnih farmah. Ta zajedavec negativno vpliva na prebavne organe piščancev in zmanjšuje njihovo rast. Če zajedavca ne odstranimo pravočasno ali ozdravimo z antibiotiki, lahko povzroči visoko stopnjo umrljivosti piščancev.
      subtitle2: Kokcidiostatiki - Preventiva in izzivi regulacije
      description2_1: Za preprečevanje razvoja kokcidije se pogosto priporoča preventivna uporaba različnih kokcidiostatikov, ki se primešajo krmilu in se uporabljajo za piščance od enega dneva starosti do pet dni pred zakolom.
      description2_2: Kljub temu, da so kokcidiostatiki označeni kot dodatki za krmo v EU, ne kot antibiotiki, se njihova uporaba tesno nadzoruje. Obstaja možnost, da ostanejo ostanki kokcidiostatikov prisotni v mesu, kar potencialno predstavlja tveganje za zdravje ljudi.
      subtitle3: Transparentnost in certifikacija 'Antibiotic FREE'
      description3_1: Nekatera podjetja kljub uporabi kokcidiostatikov pridobijo certifikat "Antibiotic FREE" za svoje končne izdelke. Na trgu je veliko ponudbe domačih piščancev "brez antibiotikov", vendar je večina teh piščancev med svojo rastjo prejemala kokcidiostatike.
      description3_2: Naša kmetija si prizadeva doseči najvišjo kakovost in zdravje mesa, zato NE UPORABLJAMO kokcidiostatikov ali drugih registriranih in neregistriranih antibiotikov ter krmnih dodatkov.
      subtitle4: Varovanje zdravja piščancev brez uporabe zdravil
      description4_1: Zaradi naše predanosti dobrobiti živali si lahko privoščimo, da se izognemo uporabi kokcidiostatikov. Naša skrb za živali vključuje redno preventivno vzorčenje nastila, ki ga analizira Nacionalni veterinarski inštitut.
      description4_2: S tem zagotavljamo zdravje in varnost naših piščancev brez potrebe po uporabi zdravil.
      description4_3: Karenca - čas, ki mora preteči od zadnjega zaužitja antibiotika/zdravila/cepiva, da sledi oz. ostanki le-teh padejo pod mejno vrednost, ki jo določa UVHVVR ali EU.
  index:
    nav: Kmetija Grgar
    title: Domači piščanci najvišje kakovosti

    heroSection:
      title: Domači piščanci
      subtitle: najvišje kakovosti
      description1: Dobrodošli na naši spletni strani, kjer vam ponujamo vrhunsko piščančje meso. Naša predanost kakovosti in dobrobiti je preprosta - piščanci se hranijo izključno z žiti lokalne pridelave in so v naši skrbni reji od prvega dne pa vse do zakola in vašega prevzema.
      description2: Tako vam zagotavljamo najboljše domače piščančje meso, ki je obenem okusno in odgovorno pridelano. Odkrijte razliko in okusite naravno kakovost v vsakem kosu mesa, ki ga ponujamo.

    ourAdvantages:
      title: Naše prednosti
      adventageNum1:
        title: 100% brez antibiotikov
        description: Naši piščanci so vzrejeni popolnoma brez antibiotikov, brez hormonov in brez vsakršnih prehranskih dopolnil.
      adventageNum2:
        title: 2x več prostora kot EU standard
        description: Naši piščanci se prosto gibljejo na svežem grgarskem zraku in za razliko od ostalih niso prenatrpani v hlevu.
      adventageNum3:
        title: 100% Slovensko
        description: Naši piščanci celo svoje življenje preživijo na Kmetiji Grgar v skrbnih rokah Kevina Reje.
      adventageNum4:
        title: Zakol in dostava v 24h
        description: Ker se zavedamo, kako pomembna vam je poleg kakovosti tudi svežina vam zagotavljamo možnost prevzema v 24h od zakola. V aplikaciji za naročanje si izberite datum dostave ter izdelke in osebno vam jih bomo dostavili.
      adventageNum5:
        title: Lokalna pridelana krma
        description: Za nadzor kakovosti hrane, ki jo zaužijejo naši piščanci, krmo zamešamo sami. Žita pridobivamo iz okolijskih kmetij, z enako trajnostno vizijo kot je naša.
      adventageNum6:
        title: Izjemnega okusa
        description: Izjemna skrb za dobrobit naših živali se odraža na več načinov. Najbolj opazna je pri kakovosti mesa, ki je neverjetno okusno. To smo dosegli tudi skozi dolgoletno preizkušanje raznih krmnih mešanic, razvoja kmetije in pridnega dela.

    products:
      title: Naša raznolika ponudba,
      subtitle: ki zadovolji vse vse vaše kulinarične želje
      andMore: ... in še mnogo več


    reviews:
      title: Kaj pravijo naše zveste stranke?
      reviewNum1:
        description: Meso je polno okusa in niti približno pusto. Boljših hrenovk še nisem jedel. Vse pohvale samo tako naprej!
        who: Matej M., Ljubljana
      reviewNum2:
        description: Že veliko let kupujemo BIO piščance, vendar še nikoli ni bil noben tako dobrega okusa kot tvoji. Hvala :)
        who: Anja P.
      reviewNum3:
        description: Piščanci so enkratni, dostavljeni so pred domača vrata na dogovorjeni dan! Odlični!
        who: Blaž Š.

    aboutUs:
      title: Dolgoletne izkušnje,
      subtitle: že več kot 100 let
      description1: Naša kmetija leži na začetku vasi Grgar, v zavetrju Svete gore. Zgodovina kmetije sega v leto 1920, na njej pa se z rejo piščancev ukvarjamo že od leta 1967. Stremimo k trajnostnemu razvoju in ničelni uporabi pesticidov in drugih za okolje škodljivih snovi.
      description2: Krmo za piščance pripravljamo sami s pomočjo lokalnih kmetov, ki podpirajo zeleno kmetijstvo in ekološko pridelavo.

    body:
      offer: Ponujamo vam
    button-banner1: Naročilo
    button-banner2: O nas
  products:
    nav: Naši izdelki
    title: Naši izdelki
    subtitle: kmetije Grgar
    wholeChicken:
      title: Cel Piščanec
      description: Celoten piščanec, ki je pripravljen za peko ali kuhanje. S svojim svežim okusom in sočno teksturo predstavlja popolno izbiro za pripravo družinskih obrokov ali posebnih priložnosti, kjer boste s ponosom postregli z vrhunskim piščančjim mesom.
    filletBreast:
      title: Prsa file
      description: Izjemno nežno in sočno meso prsi, ki se ponaša s čisto minimalno vsebnostjo maščobe. Prsa file so odlična izbira za tiste, ki si želijo hitrih in lahkotnih obrokov, saj ponujajo vrhunski okus in hranilno vrednost.
    thighs:
      title: Stegna
      description: Sočna piščančja stegna s polnim okusom in prijetno sočno teksturo. Ta kos mesa je idealen za različne načine priprave, bodisi pečen ali kuhan. Stegna so prava izbira za tiste, ki cenijo bogate okuse.
    legs:
      title: Krače
      description: Krače prinašajo okusno meso piščančjih krač, ki se ponaša z odličnim razmerjem med mesom in kostmi. To meso je izjemno primerno za gurmanske jedi, ki ljubiteljem piščančjega mesa omogočajo, da uživajo v vsakem grižljaju.
    poultry:
      title: Perutničke
      description: Sočne perutničke, ki predstavljajo odličen prigrizek ali dodatek k glavnim jedem. Njihova hrustljava tekstura in poln okus jih naredita za priljubljeno izbiro med vsemi generacijami.
    soupPacket:
      title: Juha paket
      description: Juha paket vsebuje piščančji hrbet in vrat, ki sta nepogrešljiva pri pripravi okusne in hranljive juhe. Ta paket bo vaša izbira, če želite pripraviti juho, ki bo pogrela telo in duha ter razvajala vaše brbončice z izjemnim okusom.
    liver:
      title: Jetrca
      description: Nežna in bogata piščančja jetra, ki ponujajo razkošen okus in teksturo. Idealna izbira za pripravo različnih jetrnih jedi, ki razvajajo brbončice in zadovoljijo najzahtevnejše gurmane.
    sausages:
      title: Hrenovke
      description: Hrenovke so izdelane iz kakovostnega mesa domačih piščancev, začinjene z naravnimi začimbami in brez uporabe nitritne soli ali ojačevalcev okusa. Vakumsko zapakirane so za ohranitev svežine.
  404:
    title: page not found
  blank:
    nav: Cenik
    title: Cenik
    description: Cenik
    just_blank_page_with_title: Izberite datum dostave
  order:
    nav: Naroči
    title: Naroči
    index:
      nav: Naroči
      title: Naroči
  thank_you:
    title: Hvala za naročilo!
  registration:
    nav: Registracija
    title: Registracija
    index:
      nav: Registracija
      title: Registracija
  login:
    nav: Prijava
    title: Prijava
    index:
      nav: Prijava
      title: Prijava v račun

  user:
    nav: Moj profil
    title: Uporabniške nastavitve
    index:
      nav: Moj profil
      title: Uporabniške nastavitve

menu:
  order: Naroči
  login: Prijava
  register: Registracija
  myProfile: Moj profil
  logout: Odjava
  webpage: Spletna stran
  contact: Kontakt
  aboutUs: O nas
  terms: Pogoji poslovanja


footer:
  index: Kazalo
  followUs: Sledite nam
  copyright: domacipiscanci.si. Vse pravice pridržane.
  designed: Oblikovano in programirano z ♥ // Nik Klemenc

banners:
  welcome: hello, welcome to %{app_name}!

buttons:
  submitOrder: Oddaj naročilo
  toOrder: Na naročilo
  viewAllProducts: Poglej vse izdelke
  readMore: Preveri več
  send: Pošlji

others:
  learn_more: learn more
  copy: copy
  enabled: enabled
  disabled: disabled
