components:
  language_switcher:
    change_language: change language
  theme_switcher:
    theme: theme
    change_theme: change theme
pages:
  aboutus:
    nav: About us
    title: Kmetija Grgar about us
    description: Kmetija grgar about us page
  pricelist:
    nav: Pricelist
    title: Kmetija grgar pricelist
    description: This is a pricelist page
  contact:
    nav: Contact
    title: Kmetija grgar contact
    description: Kmetija grgar contact description
  antibiotics:
    nav: Antibiotics
    title: Kmetija grgar antibiotics
    description: Kmtetija grgar antibiotics description
  body:
    offer: we offer you
  index:
    title: real[]premium[]chickens
    body:
      offer: we offer you
    button-banner1: Order
    button-banner2: About us
  404:
    title: page not found
  blank:
    nav: blank
    title: blank page
    description: this is a blank page
    just_blank_page_with_title: page for ordering
  post:
    nav: post
    title: post
    description: this is a post page

  order:
    nav: Order
    title: Order
    index:
      nav: Order
      title: Order products
  registration:
    nav: Registration
    title: Registration
    index:
      nav: Register account
      title: Register acc
  login:
    nav: Login
    title: Login
    index:
      nav: Login to acc
      title: Login to acc
  user:
    nav: User
    title: User
    index:
      nav: User settings
      title: User settings
  orders:
    nav: User orders
    title: User orders
    index:
      nav: User orders
      title: User orders page
banners:
  welcome: hello, welcome to %{app_name}!
others:
  learn_more: learn more
  copy: copy
  enabled: enabled
  disabled: disabled
