import plugin_vue3_0dCUWIVaBv from "/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@3.29.1_typescript@5.0.2_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import unhead_TJkIP8xoiy from "/app/node_modules/.pnpm/nuxt@3.3.1_@types+node@20.6.0_eslint@8.48.0_optionator@0.9.3_rollup@3.29.1_sass@1.59.3_terser_ejq5d7bj4qalkoeqycuimb25da/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_xFnzTiro5q from "/app/node_modules/.pnpm/nuxt@3.3.1_@types+node@20.6.0_eslint@8.48.0_optionator@0.9.3_rollup@3.29.1_sass@1.59.3_terser_ejq5d7bj4qalkoeqycuimb25da/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_AG5Z5uX2Jg from "/app/node_modules/.pnpm/nuxt@3.3.1_@types+node@20.6.0_eslint@8.48.0_optionator@0.9.3_rollup@3.29.1_sass@1.59.3_terser_ejq5d7bj4qalkoeqycuimb25da/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_3It8w1WKsl from "/app/node_modules/.pnpm/nuxt@3.3.1_@types+node@20.6.0_eslint@8.48.0_optionator@0.9.3_rollup@3.29.1_sass@1.59.3_terser_ejq5d7bj4qalkoeqycuimb25da/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WWqhLyhyUY from "/app/node_modules/.pnpm/nuxt3-meta-pixel@1.0.9_rollup@3.29.1/node_modules/nuxt3-meta-pixel/dist/runtime/plugin.mjs";
import plugin_FRmGFsEaPh from "/app/.nuxt/plugin.mjs";
import chunk_reload_client_4N5hkpMLUz from "/app/node_modules/.pnpm/nuxt@3.3.1_@types+node@20.6.0_eslint@8.48.0_optionator@0.9.3_rollup@3.29.1_sass@1.59.3_terser_ejq5d7bj4qalkoeqycuimb25da/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import navbar_qw38FjZisw from "/app/plugins/navbar.ts";
import ssr_plugin_Ugd6zNVKZr from "/app/node_modules/.pnpm/@vueuse+nuxt@9.13.0_nuxt@3.3.1_@types+node@20.6.0_eslint@8.48.0_optionator@0.9.3_rollup@3.29._qxcnxx4fexcuvgr5mil55fbc6q/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import aos_client_rDSYU13oOX from "/app/plugins/aos.client.ts";
import fontawesome_klhsrycjcK from "/app/plugins/fontawesome.js";
import vue_gtag_client_fbnXi0Aqdd from "/app/plugins/vue-gtag.client.js";
import vueGoogleMaps_8xmoAwKZvr from "/app/plugins/vueGoogleMaps.ts";
export default [
  plugin_vue3_0dCUWIVaBv,
  components_plugin_KR1HBZs4kY,
  unhead_TJkIP8xoiy,
  vueuse_head_polyfill_xFnzTiro5q,
  router_AG5Z5uX2Jg,
  prefetch_client_3It8w1WKsl,
  plugin_WWqhLyhyUY,
  plugin_FRmGFsEaPh,
  chunk_reload_client_4N5hkpMLUz,
  navbar_qw38FjZisw,
  ssr_plugin_Ugd6zNVKZr,
  aos_client_rDSYU13oOX,
  fontawesome_klhsrycjcK,
  vue_gtag_client_fbnXi0Aqdd,
  vueGoogleMaps_8xmoAwKZvr
]